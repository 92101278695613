import styled from "styled-components";

export const InfoCardContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 100px;
  padding: 5px;
  h1 {
    color: ${(props) => (props.color ? props.color : "#fff")};
    font-size: 16px;
  }
  #wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 80%;
    height: 70%;
    border-radius: 12px;
    border: 1px solid ${(props) => (props.color ? props.color : "#fff")};
    position: relative;
    &::before {
      position: absolute;
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      background: ${(props) => (props.color ? props.color : "#fff")};
      left: 50%;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    p {
      padding: 0;
      font-size: 35px;
      color: ${(props) => (props.color ? props.color : "#fff")};
    }
    span {
      font-size: 12px;
      margin-top: -1em;
      padding-bottom: 1em;
      color: ${(props) => (props.color ? props.color : "#fff")};
    }
  }
`;
