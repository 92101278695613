import React from 'react';
import Routes from './routes';
import "./styles.css";


function App() {
  return (
    <Routes />
  );
}

export default App;
