import styled from 'styled-components'
import {colors} from '../../assets/colors'

export const Conteiner = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    background: ${colors.lightGreen};
  
    div {
      @media (max-width: 768px){
     p{
      padding-left: 0.2em !important;
        padding-right: .25em !important;
        color: white;
        font-size:.8em;
        
     }
    }
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        display: flex;
        padding-left: 10em;
        padding-right: 25em;
        color: white;
      }
      img {
        display: flex;
        padding: 0.5em;
        cursor: pointer;
      }
    }
  `