import React from 'react';
import { Section } from './styles';

import banner from "../../../assets/images/sadiBanner.svg";

function SectionOne({handleClick} ) {

  return (
    <Section>
      <img src={banner} alt="banner"/>
      <div>
        <h1>A solução pensante</h1>
        <h2>em combate a incêndios</h2>
      </div>
      <button 
        onClick={handleClick}
        >Saiba mais</button>
      
    </Section>
    );
}

export default SectionOne;