import styled from "styled-components";
import { colors } from "../../utils/colors";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 300px;
  @media (max-width: 768px){
    width: 260px;
  }
  min-height: 370px;
  max-height: 500px;
  height: 100%;
  border-radius: 24px;
  border: 2px solid ${colors.green};
  background: transparent;
  img {
    object-fit: contain;
    width: min(240px, 80%);
    height: auto;
    border-radius: 24px;
  }
  h1 {
    color: ${colors.darkGreen};
    margin-top: 24px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  #card-title {
    display: flex;
    align-self: flex-start;
    padding-left: max(35px, 10%);
  }
  p {
    color: ${colors.green};
    width: min(240px, 80%);
  }
`;
