import React from "react";
import { Section } from "./styles";

import Card from "../../../components/Card";
import InfoCard from "../../../components/InfoCard";

import cerebro from "../../../assets/images/cerebro.png";
import waterTower from "../../../assets/images/waterTower.svg";
import fireSpot from "../../../assets/images/fireSpot.svg";
import radar from "../../../assets/images/radar.png";
import alerts from "../../../assets/images/alerts.svg";
import cameras from "../../../assets/images/cameras.svg";
import imageBanc from "../../../assets/images/imageBanc.svg";
import monitoredArea from "../../../assets/images/monitoredArea.svg";

const SectionTwo = ({ refProp = 'contato' }) => {
  return (
    <Section>
      <main ref={refProp}>
        <h1 className="title">Como funciona</h1>
        <ul>
          <li style={{paddingLeft:'1em'}}>
            <Card
              image={radar}
              title="Detecção"
              text="Utilizando câmeras 360° instaladas em pontos estratégicos, cada uma comporta mais de 21 mil análises diárias. Processamento distribuído para o melhor desempenho."
            />
          </li>
          <li>
            <Card
              image={cerebro}
              title="I.A. (Machine learning)"
              text="Com a Inteligência Artificial do SADI super aprimorada, os focos de incêndio são identificados automaticamente e alertas são emitidos."
            />
          </li>
          <li>
            <Card
              image={fireSpot}
              title="Identificação do foco"
              text="Após a identificação do foco ter sido localizada e notificada, a brigada de incêndio é acionada e recebe as coordenadas específicas correspondentes."
            />
          </li>
        </ul>
      </main>
      <footer>
        <ul>
          <li>
            <InfoCard
              title="Alertas no ano"
              image={alerts}
              text="300"
              optionalText="mil alertas"
            />
          </li>
          <li>
            <InfoCard
              title="Análise de imagens"
              image={imageBanc}
              text="4s"
              optionalText="a cada"
            />
          </li>
          <li>
            <InfoCard title="Câmeras PTZ" image={cameras} text="60x" optionalText="até 50km"/>
          </li>
          <li>
            <InfoCard title="Torres instaladas" image={waterTower} text="50" optionalText="mais de"/>
          </li>
          
          <li>
            <InfoCard
              title="Área monitorada"
              image={monitoredArea}
              text="7mi"
              optionalText="hectares"
            />
          </li>
        </ul>
      </footer>
    </Section>
  );
};

export default SectionTwo;
