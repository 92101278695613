import styled from "styled-components";
import { colors } from "../../utils/colors";

export const MainContainer = styled.div`
  display: flex;
  position: relative;
  width: 450px;
  height: 420px;
  border-radius: 24px;
  #background {
    object-fit:cover;
    width: 100%;
    height: 100%;
    position: absolute;
    filter: brightness(60%);
    z-index: 100;
    border-radius: 24px;
  }
  #title-container {
    position: absolute;
    width: ${props => props.titleWidth? props.titleWidth: '40%'};
    height: 65px;
    z-index: 200;
    top: 20px;
    background: ${colors.green};
    #title {
      position: absolute;
      top: .3em;
      left: 1em;
      padding-top: 0.5em;
      color: ${colors.white} !important;
      font-weight: 500;
      text-align: center;
    }
    p {
      position: absolute;
      top: 2.3em;
      left: 1.2em;
      color: ${colors.white};
      font-size: 14px;
      text-align: center;
    }
  }
  #text {
    position: absolute;
    width: 60%;
    padding-left: 2em;
    font-size: 14px;
    z-index: 200;
    bottom: 20px;
    color: ${colors.white};
  }
  #icon {
    position: absolute;
    z-index: 200;
    bottom: 30px;
    right: 30px;
  }
`;
