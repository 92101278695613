import React, { useRef } from "react";
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import { Hidden } from "@material-ui/core";

const Home = () => {
 
  const casesRef = useRef(null);
  const contatoRef = useRef(null);
  const servicosRef = useRef(null);



  function handleClick(e) {
    
    if (e.target.innerHTML === 'Cases') {
      casesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (e.target.innerHTML=== 'Contato') {
      contatoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (e.target.innerHTML=== 'Serviços' || e.target.innerHTML=== 'Saiba mais') {
      servicosRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div style={{overflow:'hidden'}}>
      <Header handleClick={handleClick} />
      <SectionOne handleClick={handleClick}/>
      <SectionTwo refProp={servicosRef}/>
      <SectionThree refProp={casesRef} />
      <Footer  refProp={contatoRef}/>
    </div>
  );
};

export default Home;
