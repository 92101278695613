import React from 'react';

import { Wrapper } from './styles';

function Modal({title, bodyText, buttonText, onClose}) {
  return(
    <Wrapper>
        <div id='content'>
            <h2>{title}</h2>
            <p>{bodyText}</p>
            <button onClick={onClose}>{buttonText}</button>
        </div>
    </Wrapper>
  )
}

export default Modal;