import React from "react";

import { MainContainer } from "./styles";

function FilterCard({
  backgroundImage,
  text,
  icon,
  containerText,
  containerTitle,
  titleWidth
}) {
  return (
    <MainContainer titleWidth={titleWidth}>
      <img id="background" src={backgroundImage} alt="background"></img>
      <div id="title-container">
        <h1 id="title">{containerTitle}</h1>
        <p>{containerText}</p>
      </div>
      <p id="text">{text}</p>
      <img id="icon" src={icon} alt="icon"></img>
    </MainContainer>
  );
}

export default FilterCard;
