import React, {useRef} from 'react';
import Header from '../../components/Header';
import SectionOne from './SectionOne';
import Footer from '../../components/Footer'

const About = () => {

  const casesRef = useRef(null);
  const contatoRef = useRef(null);
  const servicosRef = useRef(null);


  function handleClick(e) {
    
   
    if (e.target.innerHTML=== 'Contato') {
      contatoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  }

  return(
    <div >
      <Header handleClick={handleClick}/>
      <SectionOne/>
      <Footer refProp={contatoRef}/>
    </div>
  )

}

export default About;