import styled from "styled-components";

import { colors } from "../../../utils/colors";

export const Section = styled.div`
  display: flex;
  position: relative;
  @media (max-width: 768px){
    height:100vh;
  }
  img {
    width: 100%;
    @media (max-width: 768px){
      height:100vh;
      width: 120%;
  }
    object-fit: cover;

  }
  div {
    display: block;
    position: absolute;
    background: transparent;
    align-items: right;
    top: 307px;
    right: 260px;
    @media (max-width: 768px){
      top: 40%;
      right: 10px;
      h1 {
      font-size: 24px !important;
      color: ${colors.white};
      }
      h2 {
        font-size: 16px !important;
        color: ${colors.white};
        text-align: right;
      }
    }
    h1 {
      font-size: 54px;
      color: ${colors.white};
    }
    h2 {
      font-size: 36px;
      color: ${colors.white};
      text-align: right;
    }
  }
  button {
    @media (max-width: 768px){
      top: 55%;
      right: 10px;
      font-size: 14px;
      width: 124px;
      height: 48px;
    }
    position: absolute;
    top: 457px;
    right: 260px;
    font-size: 14px;
    width: 124px;
    height: 48px;
    background: transparent;
    color: ${colors.white};
    border: 1px solid ${colors.white};
    border-radius: 8px;
    padding: 1em;
    cursor: pointer;
  }
`;
