import React from "react";

import { MainContainer } from "./styles";

const Card = ({ text, title, image }) => {
  return (
    <MainContainer>
      <img src={image} alt="imagem" />
      <h1 id="card-title">{title}</h1>
      <p>{text}</p>
    </MainContainer>
  );
};

export default Card;
