import styled from "styled-components";

import { colors } from "../../utils/colors";

export const MainContainer = styled.div`
  display: flex;
  align-self: center;
  flex-wrap:wrap;
  width: ${props => props.innerWidth? `${props.innerWidth}px`: '100%'};
  height: 350px;
  @media (max-width: 768px){
    height: 100%;
    width: 90%;
  }
  @media (min-width: 1920px){
    width: 50%;
  }
  padding: 3em;
  border: 0px solid ${colors.green};
  border-top-width: 1px;
  justify-content: space-between;
  #info{
    display: flex;
    flex-direction:column;
    align-items:flex-start;
    
    img {
    
      margin-bottom:2em;
    }
    #address {
      margin-bottom:1em;
    
    }
    #phone-number {
      margin-bottom:2em;
    }
    button {  
      width: 180px;
      height: 48px;
      color: ${colors.white};
      background: ${colors.green};
      border-radius: 8px;
     
      cursor: pointer;
    }
  }
 
  #form-container {
    display:flex;
    flex-direction:column;
    width: 45%;
    height: 350px;
    justify-content:space-between;
   position: relative;
   @media (max-width: 768px){
    height: 100%;
    width: 100%;
  }
    p {
      text-align: left;
      width: 90%;
      font-size: 14px;
    }

    #close-button {
      position: absolute;
      width: 13px;
      height: 15px;
      right: 0;
      top: 0.3em;
      cursor: pointer;
    }
    form{
      width:100%;
      height:80%;
      gap:1em;
      display:flex;
      flex-direction:column;
    .imputs {
      align-self: center;
      border: 1px solid ${colors.green};
      border-radius: 4px;
      width: 100%;
      height: 2.5em;
      background: transparent;
      text-decoration: none;
      box-sizing:border-box;
      padding-left:1em;
    }
    .btn{
      align-self:flex-end;
      color:${colors.white};
      background-color:${colors.green};
      width:7em;
      height:2.5em;
      border-radius:6px;
      margin: 0 ;
        
      cursor: pointer;
    }
    }
  }
`;
