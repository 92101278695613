import React, {useRef, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'

import Timeline from "../../../components/TimeLine";
import TeraForm from "../../../components/TeraForm";

import leftArrow from "../../../assets/images/arrowLeft.svg";
import team from "../../../assets/images/sobre.png";
import teraLogo from "../../../assets/images/teraLogo.svg";
import xButton from "../../../assets/images/xButton.svg";

import { Section } from './styles';

const SectionOne = () => {

  const history = useHistory()

  const refUl = useRef(null)

  const [refWidh, setRefWidth] = useState(false)

  useEffect(()=>{
    if(refWidh){
      setRefWidth(refWidh.current.childNodes[0].offsetWidth *2.5)
    }
  },[refWidh])

  return (
      <Section>
        <div id="title-container">
            <img id="arrow" src={leftArrow} alt="left-arrow" onClick={()=>history.push('/')}/>
            <h1>Sobre nós</h1>
        </div>
        <p>Buscando levar aos nossos clientes uma solução completa de detecção  de incêndios, a TERA investe pesado em pesquisa e desenvolvimento de novas tecnologias relacionadas a GIS e Inteligência Artificial, bem como automação eletrônica.<br/><br/>
        Incansáveis e comprometidos, nosso foco é sempre a superação de expectativas de nossos clientes.
        </p>
        <img id="team-banner" src={team} alt="team-banner"/>
        <h1 id="timeline-title">Trajetória</h1>
        <Timeline/>
        <TeraForm innerWidth={refWidh} closeButton={xButton} logo={teraLogo} address="Av. Bento Gonçalves 4123 - Pelotas / RS" phoneNumber="(53)3228.6496" buttonText="Entrar em contato" formText="Ficou interessado em nossos serviços? Então entre em contato preenchendo o formulário abaixo!"/>
      </Section>
  );
}

export default SectionOne;