import React from "react";
import { InfoCardContainer } from "./styles";

const InfoCard = ({ color, title, image, text, optionalText = "" }) => {
  return (
    <InfoCardContainer color={color}>
      <h1>{title}</h1>
      <div id="wrapper">
        <div>
          <img src={image} alt="imagem" />
        </div>
        <div
          style={{ flexDirection: "column", justifyContent: "space-evenly" }}
        >
          <p>{text}</p>
          <span>{optionalText}</span>
        </div>
      </div>
    </InfoCardContainer>
  );
};

export default InfoCard;
