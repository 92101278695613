import React, { useState } from "react";
import emailjs from 'emailjs-com'

import Modal from '../../components/Modal'
import { MainContainer } from "./styles";

import logo from "../../assets/images/teraLogo.svg";
import xButton from"../../assets/images/xButton.svg";

const TeraForm = ({logo, phoneNumber, address, buttonText, innerWidth}) => {

  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false)

  const sendEmail = (e) =>{
    e.preventDefault()
   
    emailjs.sendForm('gmail', 'template_bkk3bts', e.target, 'user_u6cxQYxJyfsrFpii98aKS')
      .then((result) => {
          setShowModal(true)
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  console.log(innerWidth)

  return (
    innerWidth && (
  <MainContainer innerWidth={innerWidth}>
    {showModal &&( 
      <Modal
        title='Mensagem enviada'
        bodyText='Em breve entraremos em contato através do e-mail informado.'
        buttonText='Ok'
        onClose={()=>setShowModal(false)}
      />)
    }
    <div id='info'>
    <img src={logo} alt="logo-tera"/>
    <span id="address">{address}</span>
    <span id="phone-number">{phoneNumber}</span>
    <button text={buttonText} onClick={() => setShowForm(true)}>{buttonText}</button>
    </div>
    { showForm &&
      <div id="form-container">
        <img id="close-button" src={xButton} alt="close-button" onClick={() => setShowForm(false)}/>
        <p>Ficou interessado em nossos serviços? Então entre em contato preenchendo o formulário abaixo!</p>
        <form onSubmit={sendEmail}>
          <input className="imputs" type="text" name="name" placeholder="Nome"/>
          <input className="imputs" type="text" name="company" placeholder="Nome da empresa"/>
          <input className="imputs" type="text" name="email" placeholder="Email"/>
          <input className="imputs" type="text" name="phone" placeholder="Telefone"/>
          <input className='btn' type="submit" value="Enviar"/>
        </form>
      </div>
    }
    
  </MainContainer>
    )
  );
}

export default TeraForm;
