import React from "react";
import folhaImg from "../../assets/images/folha.svg";
import sadiImg from "../../assets/images/sadi.svg";
import { Link } from "react-router-dom";

import {
  Container,
  HeaderWrapper,
  Logo,
  NavBar,

} from "./styles";

const Header = ({handleClick = false}) => {
  return (
    <Container>
     
        <Logo
          as={Link}
          to='/'
        >
          <img src={folhaImg} alt="Logo"/>
          <img src={sadiImg} alt="Logo"/>
        </Logo>
        <NavBar>
          <li> <Link to="/sobre">Sobre Nós</Link></li>
          <li> <Link onClick={handleClick} to='/'>Serviços</Link></li>
          <li> <Link onClick={handleClick} to='/'>Cases</Link></li>
          <li> <Link onClick={handleClick} to='/'>Contato</Link></li>
        </NavBar>

      
    </Container>
  
  )
}

export default Header;