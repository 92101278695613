import styled from 'styled-components'
import {colors} from '../../assets/colors'

export const Timeline = styled.div`
margin:auto;
width:80% ;
height:300px;
content:'';
display:block;
@media (max-width: 768px){
    width:220% !important;
    overflow-x:scroll;
    margin-left:-45px !important;
    ul{
        width:auto !important;
    }
}
/* border-bottom: 1px solid ${colors.lightGreen}; */
ul{
   
    list-style:none;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    position: relative;
    height:100%;
    gap:2em;
    &::before{
        content:"";
        display:block;
        width:100%;
        @media (max-width: 768px){
            width:200%;
        }
        height:1px;
        background-color:${colors.lightGreen};
        position: absolute;
        top:50%;
    }
    #item{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        height:100%;   
        #dot{
            margin-top:5px;
            content:"";
            display:block;
            width:10px !important;
            height:10px;
            background-color:white;
           border-radius:50%;
           z-index:10;
           border:solid 1px ${colors.lightGreen};
        }
        #content{
            position: relative;
            background-color:${colors.lightGreen};
            color:white;
            text-align:center;
            font-size:14px;
            height:40px;
            border-radius:6px;
            margin-top:2em;
            padding:.5em;
            @media (max-width: 768px){
                font-size:10px;
                margin-top:3em;
                
            }
            &::before{
                content:"";
                display:block;
                position: absolute;
                top:100%;
                left:50%;
                transform:translateX(-50%);
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid ${colors.lightGreen};
          
            }
            
        }
        #year{
            text-align:center;
            margin-bottom:4em;
            color:${colors.lightGreen};

        }
}
}

`