import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home";

const Routes = () => {
  return (
    <BrowserRouter>
      {/* BrowserRouter é como se fosse o container das rotas */}
      <Switch>
        {/* escolher um só, ou seja que cada um seja tratado separadamente*/}
        <Route path="/" exact component={Home} />
        {/* se colocar só a barra é porque se trata da raiz do projeto */}
        <Route path="/sobre" component={About} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
