import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const Section = styled.div`
  background: ${colors.backgroundGreen};
  height: auto;
  display: flex;
  width:100%;
  flex-direction: column;
  align-items: center;
 
  main {
    @media (max-width: 768px){
      h1{
        margin-left:-10px !important;
        display:flex;
        font-size:1.8em !important;
      }
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 6vh;
    .title {
      display:flex;
      justify-content:center;
      color: ${colors.darkGreen};
      font-size: 38px;
      font-weight: 500;
      margin-left:-800px;
    }
    ul {
     
      @media (max-width: 768px){
        justify-content:space-between;
        gap:2em;
        overflow-y:scroll;
        height:auto;
        margin-left:-30px;
        width:auto;
      }
      justify-content:center;
      display: flex;
      gap:2em;
      list-style: none;
      width: 100%;
      
      li {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
      
      }
    }
  }
  footer {
    background: ${colors.green};
    width: 100%;
    @media (max-width: 768px){
      height:350px !important;
      
    }
    ul {

      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0.5em 0 1em;
      @media (max-width: 768px){
      display:flex;
      flex-wrap:wrap;
        li{
          width:50%;
          padding-bottom:10px;
        }
      }
    }
  }
`;
