import styled from "styled-components";
import { colors } from "../../../utils/colors";

export const Section = styled.div`
  background: ${colors.backgroundGreen};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  main {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    @media (max-width: 768px){
      .title{
        font-size: 28px !important;
        margin-left: 10px !important;
        
      }
      ul{
    
        display:flex;
        flex-wrap:wrap !important;
        flex-direction:column !important;
        align-items:center;
        li{
          width:90% !important;
        };
      }
    }
    .title {
      color: ${colors.darkGreen};
      font-size: 38px;
      font-weight: 500;
display:flex;
justify-content:center;
margin-left:-800px;
      margin-top: 1em;
    }
    #func-card {
      position: relative;
      display: flex;
      flex-direction: column;
      /* padding: 1em 3em 1em 0; */
      /* margin-left: 5vw; */
      /* margin-right: -1em;
      margin-top: 1em; */
      /* align-items: center; */
      width: 90%;
      height: 150px;
      border: 0px solid ${colors.green};
      border-right-width: ${(props) =>
        props.borderRightWidth ? props.borderRightWidth : "0px"};
      h1 {
        font-size: 20px;
        font-weight: 500;
        color: ${colors.darkGreen};
        padding-left: 0.8em;
      }
      img {
        position: absolute;
        right: 3.5em;
        top: .2em;
      }
      p {
        display: flex;
        align-self: center;
        width: 83%;
        padding-top: 1em;
        padding-right: 2em;
        /* padding-left: 0.1em; */
        font-size: 14px;
        color: ${colors.green};
      }
    }
    ul {
      align-self: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      list-style: none;
      width: auto;
      @media (min-width: 1920px){
        width: 60%;
      }
      padding-top: 1em;
      padding-bottom: 2em;
      @media (max-width: 768px){
        gap:1em;
      }
      #li-func-card {
        display: flex;
        width: 400px ;
        flex-direction: column;
        align-items: center;
      }
    }

    #ul-filter {
      width: 100%;
      display: flex;
      justify-content: center;
      gap:80px;
      margin-bottom: 2em;
      margin-top: 1em;
      li {
        display: flex;
        padding: 0 0em;
      }
    }
    #horizontal-pipe {
      display: block;
      align-self: center;
      width: 92%;
      height: 0%;
      border: 0.5px solid ${colors.green};
    }
  }
`;
