import React from 'react';
import { Conteiner } from './style';

import facebookIcon from "../../assets/images/facebook.svg";
import instagramIcon from "../../assets/images/instagram.svg";
import linkedinIcon from "../../assets/images/linkedin.svg";

function Footer({ refProp = 'contato' }) {
  return  <Conteiner>
  <div ref={refProp}>
    <p>2020 - Tera Informática - Todos os direitos reservados</p>
    <a href="https://pt-br.facebook.com/TeraInformatica">
      <img src={facebookIcon} alt="facebook-icon"/>
    </a>
    <a href="https://www.instagram.com/tera.informatica/">
      <img src={instagramIcon} alt="instagram-icon"/>
    </a>
    <a href="https://www.linkedin.com/company/terainformatica/">
      <img src={linkedinIcon} alt="linkedin-icon"/>
    </a>
  </div>
</Conteiner>
}

export default Footer;