export const colors = {
  green: "#3DB850",
  white: "#ffffff",
  dark: "#000000",
  background: "#F6FFED",
  darkGreen: "#092B00",
  backgroundGreen: "#E9FFD6",
};

// export default colors;
