import React, {useRef, useState, useEffect} from "react";

import {colors} from "../../../utils/colors";

import coins from "../../../assets/images/coins.svg";
import brain from "../../../assets/images/brain.svg";
import clock from "../../../assets/images/clock.svg";
import man2 from "../../../assets/images/man2.png";
import man from "../../../assets/images/man.png";
import teraLogo from "../../../assets/images/teraLogo.svg";
import xButton from "../../../assets/images/xButton.svg";
import cenibra from "../../../assets/images/Cenibra.svg";
import cmpc from "../../../assets/images/cmpc.svg";


import FilterCard from "../../../components/FilterCard";
import TeraForm from "../../../components/TeraForm";

import { Section } from "./styles";

const SectionThree = ({ refProp = 'cases' }) => {

  const refUl = useRef(null)

  const [refUlWidh, setRefUlWidth] = useState(false)

  useEffect(()=>{
    if(refUl){
      setRefUlWidth(refUl.current.childNodes[0].offsetWidth *2.5)
    }
  },[])

  return (
    <Section>
      <main>
        <h1 className="title">Funcionalidades</h1>
        <ul ref={refUl}>
          <li id="li-func-card">
            <div id="func-card" style={{ borderRightWidth: window.innerWidth>768?"1px":'0px;', borderBottomWidth:window.innerWidth<768?"1px":'0px;' }}>
              <h1>Redução de custos</h1>
              <img src={coins} alt="data-icon"></img>
              <p>
                Com a centralização da operação em um ambiente administrativo, a
                mão de obra de torristas e torres de menor valor, fazem a
                operação ter seu custo reduzido.
              </p>
            </div>
          </li>

          <li id="li-func-card">
            <div id="func-card" style={{ borderRightWidth: window.innerWidth>768?"1px":'0px;', borderBottomWidth:window.innerWidth<768?"1px":'0px;' }}>
              <h1>Tomada de decisões</h1>
              <img src={brain} alt="data-icon"></img>
              <p>
                Com a I.A., a tomada de decisão se torna mais eficiente pela
                facilidade de visualização, registro e gestores próximos para
                apoiar na decisão.
              </p>
            </div>
          </li>

          <li id="li-func-card">
            <div id="func-card">
              <h1>Sem descanso</h1>
              <img src={clock} alt="data-icon"></img>
              <p>
                O SADI trabalha 24h por dia, o ano inteiro. Em 1 ano, cada
                câmera faz mais de 7 milhões de análises, reduzindo os custos da
                operação.
              </p>
            </div>
          </li>
        </ul>
        <ul ref={refProp}  id="ul-filter">
          <li>
            <FilterCard
              backgroundImage={man}
              text="Com uso de IA (Machine Leaning) o sistema SADI da empresa TERA proporcionou uma proatividade no combate aos incêndios florestais detectando-os logo em seu início, o que melhorou as ações de combate aos mesmos na CENIBRA."
              icon={cenibra}
              containerTitle="Ronaldo Neves"
              containerText="CIO Cenibra"
            ></FilterCard>
          </li>

          <li>
            <FilterCard
              titleWidth='65%'
              backgroundImage={man2}
              text="O sistema SADI, da TERA Informática, que detecta de forma autônoma a fumaça proveniente de incêndios e alerta a central de emergência da CMPC, acrescentou uma maior agilidade na tomada de decisão frente a uma situação de emergência."
              icon={cmpc}
              containerTitle="Maycon Damasceno"
              containerText="Analista de incêndio florestal CMPC"
            ></FilterCard>
          </li>
        </ul>
      <TeraForm innerWidth={refUlWidh} closeButton={xButton} logo={teraLogo} address="Av. Bento Gonçalves 4123 - Pelotas / RS" phoneNumber="(53)3228.6496" buttonText="Entrar em contato" formText="Ficou interessado em nossos serviços? Então entre em contato preenchendo o formulário abaixo!"/>
      </main>
    
    </Section>
  );
};

export default SectionThree;
