import React from 'react';

import {Timeline} from './style'

export default function TimeLine() {

  
    return (
      <Timeline>
          <ul>
          <li id="item">
              <div id="content">1ª Implementação do SADI</div>
              <div id='dot'></div>
              <div id="year">2010</div>
          </li>
          <li id="item">
              <div id="content">Consolidação do monitoramento</div>
              <div id='dot'></div>
              <div id="year">2014</div>
          </li>
          <li id="item">
              <div id="content">Implementação de câmeras Full HD | Eyemap</div>
              <div id='dot'></div>
              <div id="year">2016</div>
          </li>
          <li id="item">
              <div id="content">Criação do banco de_imagens</div>
              <div id='dot'></div>
              <div id="year">2017</div>
          </li>
          <li id="item">
              <div id="content">SADI 2.0 Implementação de I.A.</div>
              <div id='dot'></div>
              <div id="year">2018</div>
          </li>
          </ul>
      </Timeline>
    );
  }