import styled from 'styled-components'
import {colors} from '../../utils/colors'

export const Wrapper = styled.div`
    background-color:rgba(0,0,0,0.1);
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:50000;
    #content{
        @media (max-width: 768px){
            width:80% !important;
        }
        background-color:${colors.white};
        position:absolute;
        width:500px;
        height:250px;
        border-radius:16px;
        padding:2em;
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        h2{
            color:${colors.darkGreen};
            font-size:24px;
        }
        p{
            color:${colors.green}
        }
        button{
            background-color:${colors.green};
            color:${colors.white};
            width:6em;
            height:3em;
            border-radius:4px;
            align-self:flex-end;
        }
    }
`