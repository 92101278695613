import styled from "styled-components";

import { colors } from "../../../utils/colors";

export const Section = styled.div`
    display: flex;
    background: ${colors.backgroundGreen};
    flex-direction: column;
    padding: 4em;
    height: 100%;
    align-items:center;
    #title-container {
        display: flex;
        flex-direction: row;
        width:80%;
        @media (max-width: 768px){
            h1 {
            padding-left: .5em;
            font-size: 18px !important;
            font-weight: 500;
            color: ${colors.darkGreen};
        }    
        }
        h1 {
            padding-left: .5em;
            font-size: 38px;
            font-weight: 500;
            color: ${colors.darkGreen};
        }
        #arrow {
            cursor: pointer;
        }
    }
    p {
        width:80%;
        padding-top: 1em;
        font-size: 14px;
        color: ${colors.green};
    }
    #team-banner {
        align-self: center;
        width: 80%;
        padding: 3em 0;
        @media (max-width: 768px){
            object-fit:cover;
            width:130%;
        }
    }
    #timeline-title {
        align-self:flex-end;
        margin-right:10%;
        font-size: 38px;
        font-weight: 500;
        color: ${colors.darkGreen};
    }
`;