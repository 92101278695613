import styled from "styled-components";
import { colors } from "../../utils/colors";

export const Container = styled.header`
  width: 100%;
  height: 80px;
  background-color: ${colors.green};
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-evenly;
`;

export const Logo = styled.div`
  display: flex;
  @media (max-width: 768px){
   
    height:100%;
    align-items:center;
    margin:-20px !important;
    img{
      object-fit:contain;
      height:20%;
    }
  }
`;

export const NavBar = styled.ul`
  display: flex;
  list-style: none;


  @media (max-width: 768px){
    margin:0;
    margin-left:.5em;
    li{
      padding-left:1em;
      display:flex;
      a{
        margin:0;
        font-size: 12px;
      }
    }
  }

  a {
    margin-left: 53px;
    color: ${colors.white};
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    &:visited {
      color: ${colors.white};
    }
    &:hover {
      color: ${colors.white};
    }
    &:active {
      color: ${colors.white};
    }
  }
`;
